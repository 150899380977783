@font-face {
  font-family: 'renolink-icons';
  src: url('./fonts/renolink-icons.eot?sr8dxr');
  src: url('./fonts/renolink-icons.eot?sr8dxr#iefix')
      format('embedded-opentype'),
    url('./fonts/renolink-icons.ttf?sr8dxr') format('truetype'),
    url('./fonts/renolink-icons.woff?sr8dxr') format('woff'),
    url('./fonts/renolink-icons.svg?sr8dxr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'renolink-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-User-Profile-Default:before {
  content: '\e91f';
}
.icon-User-Profile-Selected:before {
  content: '\e920';
}
.icon-Activate:before {
  content: '\e900';
}
.icon-Alert-Circle:before {
  content: '\e901';
}
.icon-Alert-Icon:before {
  content: '\e902';
}
.icon-Arrow-Filled:before {
  content: '\e903';
}
.icon-Calendar:before {
  content: '\e904';
}
.icon-Check-Circle:before {
  content: '\e905';
}
.icon-Check:before {
  content: '\e906';
}
.icon-Chevron-Down:before {
  content: '\e907';
}
.icon-Contractors:before {
  content: '\e908';
}
.icon-Customers:before {
  content: '\e909';
}
.icon-Dashboard:before {
  content: '\e90a';
}
.icon-Deactivate:before {
  content: '\e90b';
}
.icon-Delete:before {
  content: '\e90c';
}
.icon-Dot-menu:before {
  content: '\e90d';
}
.icon-Edit:before {
  content: '\e90e';
}
.icon-Employees:before {
  content: '\e90f';
}
.icon-Export:before {
  content: '\e910';
}
.icon-Market:before {
  content: '\e911';
}
.icon-Menu-Expand:before {
  content: '\e912';
}
.icon-PDF:before {
  content: '\e913';
}
.icon-Project-Managers:before {
  content: '\e914';
}
.icon-Projects:before {
  content: '\e915';
}
.icon-Regions:before {
  content: '\e916';
}
.icon-Search:before {
  content: '\e917';
}
.icon-Sorting-Arrows:before {
  content: '\e918';
}
.icon-Star:before {
  content: '\e919';
}
.icon-Unable:before {
  content: '\e91a';
}
.icon-Upload:before {
  content: '\e91b';
}
.icon-Upper-Right-Arrow:before {
  content: '\e91c';
}
.icon-Users:before {
  content: '\e91d';
}
.icon-X-Close:before {
  content: '\e91e';
}
