$body-bg: rgb(255, 255, 255);
@import '~bootstrap/scss/bootstrap.scss';

@font-face {
  font-family: 'Poppins', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
}

* {
  font-family: 'Poppins', sans-serif;
}

.font-9 {
  font-size: 0.75rem;
}

.font-12 {
  font-size: 1rem;
}
.font-13 {
  font-size: 1.08rem !important;
}
.font-14 {
  font-size: 1.125rem;
}
.font-16 {
  font-size: 1.33rem;
}
.font-18 {
  font-size: 1.5rem;
}
.font-20 {
  font-size: 1.67rem;
}
.font-24 {
  font-size: 2rem;
}
.font-26 {
  font-size: 2.165rem;
}
.font-28 {
  font-size: 2.33rem;
}
.font-32 {
  font-size: 2.67rem;
}
.font-36 {
  font-size: 3rem;
}
.font-40 {
  font-size: 3.33rem;
}
.font-48 {
  font-size: 4rem;
}
.font-60 {
  font-size: 5rem;
}
.font-72 {
  font-size: 6rem;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-normal {
  font-weight: normal;
}

.renolink-spinner {
  color: #2897ff;
  width: 7rem;
  height: 7rem;
}

.full-height {
  height: 100vh;
  min-height: -webkit-fill-available;
}

.scrollable {
  overflow: auto;
}

.form-container-customer {
  padding-left: 8%;
  padding-right: 8%;
}

.no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%;
}

.form-container {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 73vh;
  padding-bottom: 1rem;
  @include media-breakpoint-down(md) {
    max-height: 68vh;
  }
}

.no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%;
}

.btn-block {
  width: 100%;
}

h1.extra-big-h1 {
  font-size: 4.5rem;
}

.lazuli-input {
  border: none;
  height: 3.5rem;
  border-radius: 6px;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2),
    0 1px 10px 0 rgba(0, 0, 0, 0.103);
}

.lazuli-input:hover,
.lazuli-input:focus {
  border: 2px solid #2897ff;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2),
    0 1px 10px 0 rgba(0, 0, 0, 0.103);
  transition: border-color 0s;
}

.lazuli-input.error {
  border: 1px solid #ff3f34;
}

.error {
  border: 1px solid #ff3f34 !important;
}

.text-error {
  color: #ff3f34;
}

.lazuli-input-border {
  border: solid 1px rgb(182, 180, 180);
  height: 3rem;
  border-radius: 10px;
}

.lazuli-input-border-medium {
  border: solid 1px rgb(182, 180, 180);
  height: 2.5rem;
  border-radius: 10px;
}

.lazuli-button-medium {
  height: 3rem;
}

.lazuli-space-medium {
  height: 3rem;
}

select.lazuli-select {
  border: solid 1px rgb(182, 180, 180);
  height: 2.5rem;
  border-radius: 10px;
}

select.lazuli-select.lazuli-grey {
  color: #a9a9a9;
}

select.lazuli-select.lazuli-grey > option {
  color: #212529 !important;
}

select.lazuli-select-medium {
  border: solid 1px rgb(182, 180, 180);
  height: 2.5rem;
  border-radius: 10px;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.lazuli-input-border:hover,
.lazuli-input-border:focus {
  border: 2px solid #2897ff;
  transition: border-color 0s;
}

.lazuli-input-border.error {
  border: 1px solid #ff3f34;
}

.lazuli-light-blue {
  color: #2897ff;
}

.lazuli-dark-blue {
  color: #091464;
}

.lazuli-grey {
  color: #394a64;
}

.lazuli-grey-text {
  color: #5e5e5e !important;
}

.lazuli-light-grey-text {
  color: #b3b3b3 !important;
}

.lazuli-white {
  color: #eff3f6;
}

.bg-lazuli-light-blue {
  background-color: #2897ff;
}

.bg-lazuli-dark-blue {
  background-color: #091464;
}

.bg-lazuli-extra-dark-blue {
  background-color: #061f37;
}

.bg-lazuli-grey {
  background-color: #394a64;
}

.bg-lazuli-white {
  background-color: #eff3f6;
}

.bg-lazuli-green {
  background-color: #a1dd56;
}

.bg-lazuli-red {
  background-color: #ff3f34;
}

.font-weight-bold {
  font-weight: 600;
}

.font-weight-bolder {
  font-weight: 700;
}

.shadow-box {
  border: none;
  border-radius: 6px;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2),
    0 1px 10px 0 rgba(0, 0, 0, 0.103);
}

.btn.bg-lazuli-blue {
  background-color: #2897ff;
  border: none;
  font-weight: 600;
}

.btn.bg-lazuli-blue:hover {
  background-color: #1e538a;
}

.btn.bg-lazuli-outline {
  background-color: transparent;
  color: #394a64;
  border-color: inherit;
  font-weight: 600;
}

.btn.bg-lazuli-outline:hover {
  color: #2897ff;
  background-color: transparent;
  border-color: #2897ff;
}

.btn.bg-lazuli-outline-light {
  background-color: transparent;
  color: #394a64;
  border-color: inherit;
  font-weight: 600;
}

.btn.bg-lazuli-outline-light:hover {
  color: #2897ff !important;
  border-color: #2897ff;
}

.login-error-banner {
  height: 125px;
  width: 100%;
  background-color: white;
  border: 1px solid #ff3f34;
  border-radius: 10px;

  @include media-breakpoint-down(md) {
    height: 80px;
  }
}

.not-selectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.forgot-password-btn {
  background: none;
  border: none;
}

.forgot-password-btn:hover {
  text-decoration: underline;
}

.remember-me-label:hover {
  text-decoration: underline;
}
.login-error-banner-text {
  font-size: 18px;
  font-weight: 500;

  @include media-breakpoint-down(md) {
    font-size: 16px;
  }
}

.btn.bg-lazuli-blue:disabled {
  background-color: rgba(40, 151, 255, 0.5);
}

.admin-header-divider-users {
  border: 1px solid rgba(128, 128, 128, 0.281);

  @include media-breakpoint-down(md) {
    flex-wrap: nowrap;
    width: 44rem;
  }
}

.admin-header-divider-projects {
  border: 1px solid rgba(128, 128, 128, 0.281);

  @include media-breakpoint-down(md) {
    flex-wrap: nowrap;
    width: rem;
  }
}

.admin-header-divider-notes {
  border: 1px solid rgba(128, 128, 128, 0.281);
  width: 74rem;
  @include media-breakpoint-down(md) {
    flex-wrap: nowrap;
    width: 78vw;
  }
}

.admin-header-divider-subcontractors {
  border: 1px solid rgba(128, 128, 128, 0.281);

  @include media-breakpoint-down(md) {
    flex-wrap: nowrap;
    width: 62rem;
  }
}

.admin-header-divider-markets {
  border: 1px solid rgba(128, 128, 128, 0.281);

  @include media-breakpoint-down(md) {
    flex-wrap: nowrap;
    width: 54rem;
  }
}

.admin-header-divider-customers {
  border: 1px solid rgba(128, 128, 128, 0.281);

  @include media-breakpoint-down(md) {
    flex-wrap: nowrap;
    width: 70rem;
  }
}

.admin-header-divider-projects-tablet {
  border: 1px solid rgba(128, 128, 128, 0.281);

  flex-wrap: nowrap;
  width: 82rem;
}

.admin-header-divider-subcontractors-tablet {
  border: 1px solid rgba(128, 128, 128, 0.281);
  flex-wrap: nowrap;
  width: 69rem;
}

.admin-header-divider-markets-tablet {
  border: 1px solid rgba(128, 128, 128, 0.281);
  flex-wrap: nowrap;
  width: 58rem;
}

.admin-header-divider-customers-tablet {
  border: 1px solid rgba(128, 128, 128, 0.281);
  flex-wrap: nowrap;
  width: 76rem;
}

.active-table-tab {
  border-bottom: 8px solid #2897ff;
  border-radius: none;

  & > p {
    color: #1e538a;
    font-weight: 700;
  }

  & > p:hover {
    color: #1e538a;
    font-weight: 700;
  }
}

.table-tab {
  & > p {
    color: #b3b3b3;
    font-weight: normal;
  }
}

.bg-white-and-shadowed {
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2),
    0 1px 10px 0 rgba(0, 0, 0, 0.103);
  background-color: white;
}

.action-menu {
  vertical-align: middle;
  border-top: solid;
  border-top-color: #dee2e6;
  border-top-width: 1px;
  i {
    cursor: pointer;
    color: #a1a5a8;
  }

  i.enabled:hover {
    color: #091464;
  }
}

select.table-select {
  min-height: 38px;
  padding: 0rem 5rem 0rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #5e5e5e !important;
  background-color: transparent;
  font-weight: 600;
  color: #394a64;
  // bootstrap's form-select default properties
  background-image: url('./svgs/chevron-down.svg');
  background-repeat: no-repeat;
  background-position: right 0.2rem center;
  background-size: 30px;
  appearance: none;

  &:hover {
    border: 1px solid #2897ff !important;
    color: #2897ff;
    background-image: url('./svgs/chevron-down-blue.svg');
  }

  & > option {
    color: #394a64 !important;
  }
}

.sidebar.nav-link {
  &:hover {
    font-weight: 600;
  }

  &.active {
    background-color: #2897ff !important;
    border-left: 10px solid white;
    font-weight: 600;
    border-radius: 0rem 0.5rem 0.5rem 0rem;
  }
}

.dashboard-widgets {
  border-radius: 0.5rem;
  border-bottom-left-radius: none;
}

.table-tabs {
  border-radius: 0.5rem;
  border-bottom-left-radius: none;
  height: 4rem;
}

.table-tabs ul > button {
  height: 4rem;
}

.navbar-nav {
  height: 4rem;
}

.actions-bar-users {
  width: 100%;
  @include media-breakpoint-down(md) {
    min-width: 45.5rem;
    flex-wrap: nowrap;
    padding-right: 0px !important;
  }
}

.actions-bar-users-tablet {
  min-width: 60rem;
  flex-wrap: nowrap;
  padding-right: 0px !important;
}

.admin-header-divider-users-tablet {
  border: 1px solid rgba(128, 128, 128, 0.281);

  flex-wrap: nowrap;
  width: 60rem;
}

.actions-bar-projects {
  @include media-breakpoint-down(md) {
    min-width: 68rem;
    flex-wrap: nowrap;
    padding-right: 0px !important;
  }
}

.actions-bar-projects-tablet {
  min-width: 82rem;
  flex-wrap: nowrap;
  padding-right: 0px !important;
}

.actions-bar-subcontractors {
  @include media-breakpoint-down(md) {
    min-width: 62rem;
    flex-wrap: nowrap;
    padding-right: 0px !important;
  }
}

.actions-bar-subcontractors-tablet {
  min-width: 70rem;
  flex-wrap: nowrap;
  padding-right: 0px !important;
}

.actions-bar-markets {
  width: 100%;
  @include media-breakpoint-down(md) {
    min-width: 54rem;
    flex-wrap: nowrap;
    padding-right: 0px !important;
  }
}

.actions-bar-markets-tablet {
  min-width: 58rem;
  flex-wrap: nowrap;
  padding-right: 0px !important;
}

.actions-bar-customers {
  @include media-breakpoint-down(md) {
    min-width: 71rem;
    flex-wrap: nowrap;
    padding-right: 0px !important;
  }
}

.actions-bar-customers-tablet {
  min-width: 76rem;
  flex-wrap: nowrap;
  padding-right: 0px !important;
}

.actions-bar-Actions {
  @include media-breakpoint-down(md) {
    min-width: 30rem;
    flex-wrap: nowrap;
    padding-right: 0px !important;
  }
}

.table-tab-font {
  @extend .font-14;

  @include media-breakpoint-down(md) {
    font-size: 11px;
  }
}

.add-user-button-font {
  @extend .font-12;

  @include media-breakpoint-down(md) {
    font-size: 12px;
  }
}

.sort-button {
  background-color: transparent;
  border: none;
}

.not-sortable {
  margin-bottom: 0.375rem;
}

.page-link {
  border: none !important;
  color: gray;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.page-item {
  border: none !important;

  &.active .page-link {
    font-weight: bold;
    background-color: white;
    color: #2897ff !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.hover-dark-blue:hover,
.hover-dark-blue:hover > * {
  color: #2897ff;
}

body,
html {
  height: 100%;
  background-color: #eff3f6;
}

.reset-confirmation-text {
  font-weight: normal;
}

.optional-text {
  color: rgb(197, 197, 197);
}

.unclickable {
  cursor: default;
}

button.lazuli-light-blue-border {
  color: #2897ff;
  background-color: transparent;
  border-color: #2897ff;
  border-width: 3px;
}

button.lazuli-light-blue-border:hover {
  color: #1e538a;
  background-color: transparent;
  border-color: #1e538a;
  border-width: 3px;
}

hr.blue-separator {
  border-top: 3px solid #2699fb;
  opacity: 1;
}

hr.admin-header-separator {
  border-top: 3px solid #c4c4c4;
  opacity: 1;
  margin: 0.67rem 0;
}

hr.customer-separator {
  border-top: 1px solid #c4c4c4;
  opacity: 1;
}

hr.contact-separator {
  border-top: 2px solid black;
  opacity: 1;
}

.input-group-text.search-button {
  background-color: white;
  border-right: none;
}

.input-group-text.clear-search-button {
  background-color: white;
  border-left: none;
}

.form-control {
  padding: 0.25rem 0.5rem !important;
}

.form-control.table-searchbar {
  border-left: none;
}

.forgot-password-description-txt {
  font-size: 1.17rem;
  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }
}

.navbar-border-bottom {
  border-bottom: 4px solid #2897ff;
}

.sidebar {
  transition: all 600ms;

  &.hidden-sidebar {
    margin-left: -300px;
    transition: all 300ms ease-in-out !important;
  }
}

.desktop-animation {
  transition: width 300ms ease-in-out;
}

.sidebar-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 300ms ease-in-out;
}

.sidebar-nav-item {
  width: 100%;
}

.fade-in {
  animation: fadeInAnimation 0.3s;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.toggle-sidebar-button.btn:focus {
  box-shadow: none;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(-100px);
  transition: all 300ms ease-in-out;
  z-index: 5;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5) !important;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(-100px);
}

.ReactModal__Content {
  top: 30% !important;
  left: 50% !important;
}

.big-icon {
  font-size: 12rem;
}

i.alert-active {
  color: red !important;
}

i.alert-inactive {
  color: white;
}

tr:hover {
  i.alert-inactive {
    color: transparent !important;
  }
}

.mobile-fixed-pagination {
  @include media-breakpoint-down(md) {
    background-color: white;
    width: 100vw;
    height: 50px;
    position: fixed;
    bottom: 0px;
    left: 0px;
  }
}

.table-mobile-margin-hack {
  @include media-breakpoint-down(md) {
    margin-bottom: 2rem;
  }
}

div.progress {
  border-radius: 1.25rem;
  height: 0.6rem;
  background-color: #ebebeb;
}

tr:hover {
  div.progress {
    background-color: white;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
  }
}

.table-tooltip > .tooltip-inner {
  background-color: #eff3f6;
  border: 2px solid #2897ff;
  color: #2897ff;
}

.table-tooltip > .tooltip-arrow::before {
  border-top-color: #2897ff;
}

.wide-tooltip > .tooltip-inner {
  min-width: 25rem;
  white-space: break-spaces;
  word-wrap: break-word;
}

.form-label-style {
  font-weight: 500;
  color: #394a64;
  font-size: 1.1rem;
}

.login-form-label-style {
  font-weight: 600;
  color: #394a64;
  font-size: 1.2rem;
}

.flex-grow-slightly {
  flex-grow: 0.1;
}

.header-action-buttons {
  width: 30rem;
  height: 2.5rem;
}

.header-action-buttons > * {
  height: 100%;
}

.header-action-buttons-mobile {
  width: 12rem;
  height: 2.5rem;
}

.form-check-input:disabled {
  background-image: url('./svgs/disabled-radio-background.svg') !important;
  background-color: #eff3f6;
  border-color: #b3b3b3;
  opacity: 1;
}

.text-wrap-anywhere {
  line-break: anywhere;
}

i.signed {
  margin-left: 2.4rem;
}

span.hyphen {
  margin-left: 1.8rem;
  @include media-breakpoint-down(sm) {
    margin-left: -2rem;
    display: contents;
  }
}

span.w9-hyphen {
  margin-left: 0.65rem;
  @include media-breakpoint-down(sm) {
    margin-left: -2rem;
    display: contents;
  }
}

span.date-hypen {
  margin-left: 0.65rem;
}

i.mobile-check-hack {
  @include media-breakpoint-down(sm) {
    margin-left: -2rem;

    display: contents;
  }
}

.required-tag {
  color: red;
}

input.lazuli-checkbox {
  height: 1.5em;
  width: 1.5em;
  margin-right: 0.5rem;
}

input.lazuli-checkbox-image {
  height: 1.1em;
  width: 1.1em;
}

input.lazuli-checkbox:checked {
  border-color: #2897ff;
  background-color: #2897ff;
}

.vertical-align-sub {
  vertical-align: sub;
}

.dropzone {
  background-color: #eff3f6;
  min-height: 180px;
  color: #b3b3b3;
  background: #f9f9f9;
  border: 3px dashed #b3b3b3;
}
a.dropdown-item {
  font-weight: 500;
  color: #5e5e5e;
}
a.dropdown-item:hover {
  color: #2897ff;
  background-color: transparent;
}

.number-input-no-arrows {
  -moz-appearance: textfield;
}

.number-input-no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.date-input-icon {
  position: absolute;
  top: 2px;
  right: 0px;
  font-size: 2rem;
  pointer-events: none;
  align-self: center;
}

.position-relative {
  position: relative;
}

.disabled {
  border-color: #c4c4c4 !important;
  background-color: #c4c4c4 !important;
  pointer-events: none;
}

.no-click {
  pointer-events: none;
}

.fake-hyperlink {
  color: #061f37;
  text-decoration: underline;
  cursor: pointer;
}

hr.breakline {
  height: 4px;
  color: #5e5e5e;
}

hr.breakline-thin {
  height: 1px;
  color: #5e5e5e;
}

.dashboard-card-title {
  font-size: 24px;
  font-weight: 600;
  color: #394a64;
  margin-bottom: 0rem;
  @include media-breakpoint-down(sm) {
    font-size: 18px;
  }
}

.dashboard-card-body-text {
  font-size: 40px;
  font-weight: 500;
  color: #1e538a;
  margin-bottom: 0rem;
  @include media-breakpoint-down(sm) {
    font-size: 24px;
  }
}

.dashboard-card-icon {
  font-size: 2.67rem;
  @include media-breakpoint-down(sm) {
    font-size: 2rem;
  }
}

.dashboard-table-title {
  font-size: 22px;
  font-weight: 600;
  color: #394a64;
  margin-bottom: 0.75rem;
}

.admin-header-date {
  font-size: 24px;
  font-weight: 500;
  color: #394a64;
  @include media-breakpoint-down(md) {
    font-size: 16px;
  }
}

h1.welcome-message {
  font-size: 1.85rem;
  font-family: Roboto;
  @include media-breakpoint-down(md) {
    font-size: 24px;
  }
}

.raised-context {
  margin-top: -1rem;
  margin-right: -1rem;
}

.multiline-text {
  white-space: break-spaces;
  word-wrap: break-word;
}

a.document-url {
  color: #212529;
  text-decoration: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  display: flex;

  color: white;
  border-radius: 1rem;
}

.image-selected {
  opacity: 1 !important;
}

.overlay:hover {
  opacity: 1;
}

.overlay-text {
  margin: auto;
  font-size: 0.7rem;
}

.image-size {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 1rem;
}

.center-dashboard-column {
  padding-left: 2rem !important;
}

.inline {
  display: inline-block !important;
}

.logout-button {
  position: absolute;
  background-color: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
  right: 2.25rem;
  top: 3rem;
  z-index: 3;
}

.logout-button:hover {
  color: #2897ff;
  border-color: #2897ff;
}

.logout-button-mobile {
  position: absolute;
  right: 1rem;
  top: 3rem;
  z-index: 3;
  background-color: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.dashboard-card-disable {
  background-color: #ebe8e8;
}

.centered-header {
  margin: auto;
}
